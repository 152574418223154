import React from "react";
import { graphql } from "gatsby";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CardBody from "components/Card/CardBody.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import termsStyle from "ps-assets/jss/termsOfUsageStyle.jsx";
import Layout from "ps-components/Layout/Layout.jsx";
import Captcha from "ps-components/Captcha/Captcha.jsx";
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import CustomSnackbar from "components/Snackbar/Snackbar";
import Card from "components/Card/Card";
import PropTypes from "prop-types";

import { Helmet } from "react-helmet";

import FormHelperText from "@material-ui/core/FormHelperText";
var apiBaseUrl = process.env.API_URL;
class DND extends React.Component {
  state = {
    refreshCaptcha: false,
    submitStatus: ``,
    name: [``, true],
    mobileNumber: [``, true],
    comments: [``, true],
    email: [``, true],
    submitButton: false,
    captchaText: ``,
    inputCaptchaText: ``,
    ipdata: ``
  };

  setCaptchaText = (captchaText, inputCaptchaText) => {
    this.setState({
      captchaText: captchaText,
      inputCaptchaText: inputCaptchaText
    });
  };
  setSubmitStatus = () => {
    this.setState({
      submitStatus: ``
    });
  };
  handleSubmit = async e => {
    e.preventDefault();
    let url = `${apiBaseUrl}form/dnd`;
    var emailRegex = new RegExp(/^\S+@\S+\.\S+$/);
    let name = this.state.name[0];
    let mobile = this.state.mobileNumber[0];
    let comments = this.state.comments[0];
    let email = this.state.email[0];

    this.setState({
      submitButton: true
    });

    let data = {
      name: name != `` ? name : ``,
      mobile: mobile != `` ? mobile : ``,
      email: email != `` ? email : ``,
      comments: comments != `` ? comments : ``,
      userip: this.state.ipdata
    };
    let params = {
      method: `POST`,
      mode: `cors`,
      cache: `no-cache`,
      credentials: `same-origin`,
      headers: {
        "Content-Type": `application/json`
      },
      body: JSON.stringify(data)
    };

    if (
      name.length !== 0 &&
      this.state.name[1] === true &&
      (mobile.length !== 0 &&
        mobile.length === 10 &&
        this.state.mobileNumber[1] === true) &&
      (email.length !== 0 && this.state.email[1] === true) &&
      (comments.length !== 0 && this.state.comments[1] === true) &&
      this.state.email[0].search(emailRegex) === 0 &&
      this.state.captchaText.split(` `).join(``) ===
        this.state.inputCaptchaText &&
      this.state.captchaText.split().join(``) != `` &&
      this.state.inputCaptchaText != ``
    ) {
      let res = await fetch(url, params);

      if (res.status !== 200) {
        console.log(`error`);
        this.setState({
          submitStatus: `errored`
        });
      } else {
        this.setState({
          name: [``, true],
          mobileNumber: [``, true],
          comments: [``, true],
          email: [``, true],
          refreshCaptcha: true,
          submitStatus: `success`,
          submitButton: false,
          captchaText: ``,
          inputCaptchaText: ``
        });
      }
    }
  };

  updateRefreshCaptcha = () => {
    this.setState({
      refreshCaptcha: false
    });
  };

  handleChange = e => {
    // this.setState({ [e.target.id]: [e.target.value, true] });
    if (e.target.id === `mobileNumber`) {
      if (!isNaN(e.target.value) && e.target.value.length <= 10) {
        this.setState({ [e.target.id]: [e.target.value, true] });
      }
    } else if (e.target.id === `name`) {
      if (e.target.value.search(`^[a-zA-Z. ']*$`) === 0) {
        this.setState({ [e.target.id]: [e.target.value, true] });
      }
    } else {
      this.setState({ [e.target.id]: [e.target.value, true] });
    }
  };
  componentDidMount = async () => {
    if (this.state.ipdata === ``) {
      let response = await fetch(`https://api.ipify.org/`);
      let NetworkIp = await response.text();
      this.setState({
        ipdata: NetworkIp
      });
    }
  };
  render() {
    var phoneno = new RegExp(
      /^[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-/\s.]?[0-9]{4}$/
    );
    var emailRegex = new RegExp(/^\S+@\S+\.\S+$/);
    let { classes, data } = this.props;
    var Images = data.allStrapiImages.edges;
    var clrImage = Images[1];
    return (
      <Layout
        image2={
          clrImage &&
          clrImage.node &&
          clrImage.node.content &&
          clrImage.node.content.childImageSharp.fluid
        }
        small={true}
        title={``}
        summary={``}
      >
        <Helmet key="helmetTags">
          <title>DND Registration | StarHealth.in</title>
        </Helmet>
        {process.env.ENABLE_HELMET == `true` && (
          <Helmet key="helmetTags">
            <link
              rel="canonical"
              href="https://www.starhealth.in/dnd-registration"
            />
            <meta name="title" content="DND Registration | StarHealth.in" />
            <meta name="twitter:title" content="DND Registration | StarHealth.in" />
            <meta
              name="description"
              content="We understand that some of you may not wish to be contacted over phone or email or sms for promotions. You can go ahead and use the DND form given and register if you want us to remove from our lists."
            />
            <meta property="og:title" content="DND Registration | StarHealth.in" />
            <meta property="og:description" content="We understand that some of you may not wish to be contacted over phone or email or sms for promotions. You can go ahead and use the DND form given and register if you want us to remove from our lists." />
            <meta property="og:url" content="https://www.starhealth.in/dnd-registration"/>
            <meta name="DND Registration | StarHealth.in" />
            <meta name="twitter:description" content="We understand that some of you may not wish to be contacted over phone or email or sms for promotions. You can go ahead and use the DND form given and register if you want us to remove from our lists." />
            <meta property="twitter:url" content="https://www.starhealth.in/dnd-registration" />
          </Helmet>
        )}
        <div
          className={classNames(classes.main, classes.mainRaised)}
          style={{ marginBottom: 100 }}
        >
          <div className={classes.section}>
            <div className={classes.container}>
            <h1  className={classes.h1title} style={{textAlign:'center'}}>DND Registration</h1>
              {this.state.submitStatus === `success` && (
                <CustomSnackbar
                  open={true}
                  duration={6000}
                  status={`success`}
                  message={`Successfully registered for DND`}
                  flag={this.setSubmitStatus}
                />
              )}
              {this.state.submitStatus === `errored` && (
                <CustomSnackbar
                  open={true}
                  duration={6000}
                  status={`error`}
                  message={`We are currently facing issues, please try again later`}
                  flag={this.setSubmitStatus}
                />
              )}
              <GridContainer
                className={`${classes.container} ${classes.sizefix}`}
              >
                <GridItem
                  md={8}
                  className={`${classes.mrAuto} ${classes.mlAuto} ${classes.textLeft}`}
                >
                  <div>
                    {/* <h2 className={`${classes.title} ${classes.textCenter}`}>
                      {`DND Registration`}
                    </h2> */}
                    <h5
                      className={classes.description}
                      style={{ paddingTop: `50px` }}
                    >
                      <span>
                        We (Star Health Insurance and its
                        representatives/agents) aim to keep our valued customers
                        updated with the latest products and services we offer.
                        The information is conveyed in the form of e-mails, SMS
                        and through phone only to those whom we find our
                        products and services beneficial.
                      </span>
                      <br /> <br />
                      <span>
                        Privacy is of utmost importance, and we understand that
                        some of you may not wish to be contacted over the
                        phone/SMS/e-mail for promotions. You may use the DND
                        form given below and register if you want to opt out
                        from our lists.
                      </span>
                      <br />
                      <br />
                      <span>
                        Please allow 30 working days for the removal of the
                        specified numbers from our lists.
                      </span>
                      <br />
                      <br />
                    </h5>
                  </div>
                </GridItem>
                {/* <GridItem
              md={12}
              className={`${classes.mrAuto} ${classes.mlAuto} ${
                classes.textCenter
              }`}
            /> */}
                <GridItem
                  xs={12}
                  sm={8}
                  md={8}
                  className={`${classes.mrAuto} ${classes.mlAuto} ${classes.textCenter}`}
                >
                  <Card className={classes.card1}>
                    <form onSubmit={this.handleSubmit}>
                      <CardBody>
                        <GridContainer>
                          <GridItem xs={12} sm={6} md={6}>
                            <CustomInput
                              labelText="Name *"
                              id="name"
                              formControlProps={{
                                // required: true,
                                fullWidth: true
                              }}
                              inputProps={
                                {
                                  // required: true
                                  // pattern : ".{3,}"
                                }
                              }
                              onChange={this.handleChange}
                              value={this.state.name[0]}
                              error={
                                this.state.submitButton &&
                                this.state.name[1] &&
                                this.state.name[0].length === 0
                              }
                            />
                          </GridItem>
                          <GridItem xs={12} sm={6} md={6}>
                            <CustomInput
                              labelText="Comments *"
                              id="comments"
                              formControlProps={{
                                // required: true,
                                fullWidth: true
                              }}
                              inputProps={
                                {
                                  // required: true,
                                  // multiline: `true`,
                                  // rows: 5
                                }
                              }
                              onChange={this.handleChange}
                              value={this.state.comments[0]}
                              error={
                                this.state.submitButton &&
                                this.state.comments[1] &&
                                !this.state.comments[0]
                              }
                            />
                          </GridItem>
                          <GridItem xs={12} sm={6} md={6}>
                            <CustomInput
                              labelText="email *"
                              id="email"
                              formControlProps={{
                                // required: true,
                                fullWidth: true
                              }}
                              inputProps={
                                {
                                  // required: true,
                                  // type: `email`
                                }
                              }
                              onChange={this.handleChange}
                              value={this.state.email[0]}
                              error={
                                (this.state.submitButton &&
                                  this.state.email[1] &&
                                  !this.state.email[0]) ||
                                (this.state.email[0].length !== 0 &&
                                  this.state.email[0].search(emailRegex) !== 0)
                              }
                            />
                            {this.state.email[0].length != 0 &&
                              this.state.email[0].search(emailRegex) !== 0 && (
                              <FormHelperText id="my-helper-text" error>
                                  Email Id is invalid{` `}
                              </FormHelperText>
                            )}
                          </GridItem>
                          <GridItem xs={12} sm={6} md={6}>
                            <CustomInput
                              labelText="Mobile Number *"
                              id="mobileNumber"
                              formControlProps={{
                                // required: true,
                                fullWidth: true
                              }}
                              inputProps={
                                {
                                  // required: true,
                                  // title: `Please enter 10 digits`,
                                  // pattern: `[0-9]{10}`
                                }
                              }
                              onChange={this.handleChange}
                              value={this.state.mobileNumber[0]}
                              error={
                                (this.state.submitButton &&
                                  this.state.mobileNumber[1] &&
                                  !this.state.mobileNumber[0]) ||
                                (this.state.mobileNumber[0].length != 0 &&
                                  this.state.mobileNumber[0].search(phoneno) !==
                                    0)
                              }
                            />
                            {this.state.mobileNumber[0].length != 0 &&
                              this.state.mobileNumber[0].search(phoneno) !==
                                0 && (
                              <FormHelperText id="my-helper-text" error>
                                  Mobile Number is Numeric{` `}
                              </FormHelperText>
                            )}
                          </GridItem>
                          <GridItem
                            xs={12}
                            sm={12}
                            md={12}
                            style={{ paddingTop: 27 }}
                          >
                            <Captcha
                              fullWidth
                              nextLine={false}
                              refresh={this.state.refreshCaptcha}
                              updateRefresh={this.updateRefreshCaptcha}
                              submitButton={this.state.submitButton}
                              setCaptchaText={this.setCaptchaText}
                            />
                          </GridItem>
                          <GridItem
                            xs={12}
                            sm={12}
                            md={12}
                            style={{ paddingTop: 27, textAlign: `left` }}
                          >
                            <Button
                              color="primary"
                              round
                              type={`submit`}
                              className={`${classes.mrAuto} ${classes.mlAuto} ${classes.textCenter} ${classes.buttonfix}`}
                            >
                              Register
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </form>
                  </Card>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withStyles(termsStyle)(DND);

DND.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object
};

export const DNDQuery = graphql`
  query DND {
    allStrapiImages(
      filter: { section: { category: { eq: "dnd_registration" } } }
    ) {
      edges {
        node {
          id
          sequence
          content {
            publicURL
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
